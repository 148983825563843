.StationSelection {
  background: #fff;
  height: 100vh;
}

.selectStationDropdown {
  height: 40px !important;
  border-radius: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 0 12px !important;
  border: 1px solid #DDD !important;
}
.selectStationDropdown-error {
  height: 40px !important;
  border-radius: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 0 12px !important;
  border: 1px solid #d32f2f !important;
}

.selectPassengerTile {
  height: 32px !important;
  border-radius: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 4px 12px 4px 12px !important;
  margin-left: 5px !important;
}

.passengerIcons {
  color: #1a1a1a;
}

.calendarField {
  padding: 8px 12px !important;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
}

.selectPassengerTileIcon {
  height: 32px !important;
  border-radius: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin-left: 5px !important;
  padding: 4px 8px 4px 8px !important;
}

/* ~~~~~~~~~~~~~Select Station Component~~~~~~~~~~~~~ */
.sliderStationList {
  position: absolute !important;
  top: 30px !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  border-radius: 0 0 !important;
  /* overflow: scroll !important; */
  overflow-x: hidden !important;
  box-shadow: 0 0 0 !important;
  max-width: 640px !important;
  margin: auto !important;
}
.selectStationSliderHeader {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin: 16px 0 !important;
}
.loaderHeader {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 16px 0 !important;
}
.loaderContainer {
  margin: 16px 0 !important;
  height: 100% !important;
  text-align: center;
}
.spinnerComponent {
  display: flex;
  justify-content: center;
  margin: 30px;
}
.stationlistContainer {
  margin: 16px 0 !important;
  height: 100% !important;
}
.stationNameBlock {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #ddd !important;
  padding: 12px 0 !important;
}
.stationName {
  font-weight: bold !important;
  font-size: 14px !important;
  color: #1a1a1a !important;
}
.passengerNameBlock {
  display: flex !important;
  align-items: center !important;
  padding: 12px 0 !important;
}
.passengerBlock {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #ddd !important;
}
.passengerInfo {
  margin-left: 10px !important;
}

/* ~~~~~~~~~~~~~Select Station Card Price Component~~~~~~~~~~~~~ */
.stationStatus {
  background: #F2F2F2 !important;
  color: #999999 !important;
  padding: 5px 8px 5px 8px !important;
  font-weight: bold !important;
  font-size: 12px !important;
  border-radius: 32px !important;
}
.stationStatusCode {
  background: #eae8ec !important;
  color: #615a5a !important;
  padding: 2px 8px 2px 8px !important;
  font-size: 10px !important;
  border-radius: 100px !important;
  text-align: center;
}
.stationCardName {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #1a1a1a !important;
  padding: 4px 8px 4px 8px;
}
.stationCardClass {
  font-size: 11px !important;
}
.stationCardPrice {
  font-weight: bold !important;
  font-size: 14px !important;
}
.stationTimeLabel {
  font-size: 9px !important;
}
.stationTime {
  font-size: 12px !important;
  color: #1a1a1a !important;
  font-weight: 700 !important;
  padding: 2px 8px 2px 8px;
}

.grayOut {
  color: #777 !important;
}

/* ~~~~~~~~~~~~~Redirect Component~~~~~~~~~~~~~ */
.redirectPageText {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 13px 0px 13px;
  color: #333333;
  font-size: 16px;
}

.listingFooter {
  /* position: fixed; */
  width: 100%;
  bottom: 0px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 60px;
  box-shadow: 0px -4px 8px rgba(97, 90, 90, 0.12),
    0px 0px 0px 0px rgba(97, 90, 90, 0.08);
}

.footerBlockLeft {
  margin-left: -5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 10px;
  cursor: pointer;
}

.footerBlockRight {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 10px;
  cursor: pointer;
}

.terms {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px;
  color: #ada6ae;
  align-items: center;
  text-align: center;
}

.StationSelectionForm {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
  padding: 0 16px 16px;
  border-bottom: 1px solid #DDD;
}
.divider {
  border-top: 1px solid #f0eded;
  width: 100%;
  margin-right: 10px;
}
.swap-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.calendar-icon {
  width: 20px;
}

.disclaimer{
  padding: 20px 8px 0px;
}