.bag-header {
  padding: 0 20px;
  /* box-shadow: 1px 1px 8px 0px rgb(0 0 0 / 15%); */
}
.bag-header .bag-header-title {
  position: relative;
}
.bag-header .bag-header-title button {
  position: absolute;
}

.bag-container {
  padding: 0;
  max-width: 640px;
  margin: 0 auto;
  background-color: #f2f2f2;
  height: 100vh !important;
  overscroll-behavior: contain;
  overscroll-behavior-y: contain;
}

.baggage-container {
  z-index: 1;
  overflow-x: auto;
  display: flex;
  font-size: 14px;
}

.baggage-container::-webkit-scrollbar {
  display: none;
}

.bag-segment-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.baggage-active {
  opacity: 1 !important;
  background: #EAEAEA !important;
  border: 2px solid #1a1a1a !important;
  color: #1a1a1a !important;
}
.bag-summary-slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0 0;
  /* overflow: scroll; */
  overflow-x: hidden;
  box-shadow: 0 0 0;
  max-width: 640px;
  margin: auto;
}
.baggage {
  opacity: 0.7;
  min-width: 90px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7px 16px;
  gap: 2px;
  height: 56px;
  border: 1px solid #ada6ae;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  color: #ada6ae;
}
.bag-flight-type {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
}
.bag-pass-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.bag-comp {
  padding-top: 20px;
  border-bottom: 8px solid #f7f6f6;
}
.bag-pass-box {
  border-bottom: 2px solid #f7f3f7;
  padding-bottom: 20px;
}
.bag-passenger {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.bag-passenger span:last-child {
  font-weight: 600;
}
.bag-airports-box {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.bag-arrow {
  margin: 0 6px;
}
.bag-airports-box img {
  display: inline-block;
  margin-right: 7px;
  width: 10px;
  height: 10px;
  padding: 3px;
  border-radius: 50%;
  border: 1px solid lightgrey;
  object-fit: contain;
}
.bag-airports {
  display: flex;
  align-items: center;
}

.bag-paper-container {
  padding: 0 20px 20px;
  height: calc(100% - 170px);
  overflow: auto;
}
.bag-slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0 0;
  overflow-x: hidden;
  box-shadow: 0 0 0;
  max-width: 640px;
  margin: auto;
}
.bag-summary-box {
  margin: 2px 20px 10px;
}
.bag-submit-box {
  padding: 15px 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bag-submit-box button {
  width: 120px;
  margin-top: 0 !important;
}
.bag-select-summary-item {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
